export default {
  searchForm: {
    'popup-header': true,
    currentTabId: 0,
    currentSortType: '1',
    price_from: '',
    locationIds: [],
    error: false,
    resetPopupHandler: null,
    resetButtonIsDisabled: false,
    locationsResetButtonIsDisabled: false,
    chosenLocationNames: [],
    currentCityIsDeferred: false,
    currentCity: '',
    defaultState: {
      cityId: '1',
      leased: 'false',
      categoryId: '1'
    },
    currentState: null
  },
  drawerUI: {
    isVisible: false
  },
  errorUI: {
    isError: false
  },
  blockerDetector: {
    isBlockerActive: false
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as actionCreators from '../../actions';
import { getLangDefinition } from '../../utils/helpers';
import HeaderPlaceholder from '../Placeholders/HeaderPlaceholder';
import Drawer from '../Drawer';
import AdblockerDetector from '../Banner/BlockerDetector';
import FooterPlaceholder from '../Placeholders/FooterPlaceholder';
import MenuPlaceholder from '../Placeholders/MenuPlaceholder';
import Loader from '../Loader';

import '../../styles/layout.scss';

const Header = dynamic(() => import('../Header'), {
  loading: HeaderPlaceholder,
  ssr: false
});

const Footer = dynamic(() => import('../Footer'), {
  loading: FooterPlaceholder,
  ssr: false
});

const Menu = dynamic(() => import('../Menu'), {
  loading: MenuPlaceholder,
  ssr: false
});

const mapStateToProps = state => {
  return {
    isVisible: state.drawerUI.isVisible
  };
};

const Layout = ({ children, isVisible, toggleDrawer, setBlockerDetector }) => {
  const {
    i18n: { language }
  } = useTranslation();
  const allClasses = classNames(
    'main-content',
    `locale-${getLangDefinition(language)}`
  );

  return (
    <div className={allClasses}>
      <Header />
      <Drawer
        isVisible={isVisible}
        toggleDrawer={toggleDrawer}
        additionalClass="drawer-nav"
      >
        {isVisible && <Menu />}
      </Drawer>
      <AdblockerDetector setBlockerDetector={setBlockerDetector} />
      {children}
      <Loader pageLoader={true} />
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  i18n: PropTypes.object,
  toggleDrawer: PropTypes.func,
  isVisible: PropTypes.bool,
  setBlockerDetector: PropTypes.func
};

export default connect(mapStateToProps, actionCreators)(Layout);
